(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/percentage-reduce/assets/javascripts/data.js') >= 0) return;  svs.modules.push('/components/sport/percentage-reduce/assets/javascripts/data.js');
"use strict";


class ReduceData {
  constructor(rows, outcome, weight) {
    this.rows = rows; 
    this.outcome = outcome; 
    this.weight = weight; 
    this.actual = 0; 
    this.picks = 0; 
  }
  getDistanceFromTargetWeight() {
    const targetWeight = this.weight;
    const currentWeight = this.actual;
    return Math.abs(targetWeight - currentWeight);
  }
  updateActual(totalPicks) {
    let newActualWeight;
    if (this.picks === 0) {
      newActualWeight = 0;
    } else {
      newActualWeight = this.picks / totalPicks * 100;
    }
    this.actual = newActualWeight;
  }
}
setGlobal('svs.components.sport.percentageReduce.ReduceData', ReduceData);

 })(window);