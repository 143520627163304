(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/percentage-reduce/assets/javascripts/random.js') >= 0) return;  svs.modules.push('/components/sport/percentage-reduce/assets/javascripts/random.js');
"use strict";

const crypto = window.crypto || window.msCrypto;
const shouldUseSvsRandom = svs.core.detect.feature('ft-svs-random');
const random = () => {
  const arr = new Uint32Array(1);
  crypto.getRandomValues(arr);
  return arr[0] / (0xffffffff + 1);
};
const randIntBetween = (min, max) => {
  if (shouldUseSvsRandom) {
    return svsRandom.getRandomInt(min, max);
  }
  return Math.floor(random() * (max - min + 1) + min);
};
const randomFloatBetween = function (min, max) {
  let fixed = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 4;
  if (shouldUseSvsRandom) {
    return parseFloat(svsRandom.getRandomFloat(min, max, fixed));
  }
  const scale = 10 ** fixed;
  const num = randIntBetween(min * scale, max * scale);
  const numFixed = (num / scale).toFixed(fixed);
  return parseFloat(numFixed);
};
setGlobal('svs.components.sport.percentageReduce.random', {
  randomFloatBetween,
  randIntBetween
});

 })(window);