(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/percentage-reduce/assets/javascripts/reducer.js') >= 0) return;  svs.modules.push('/components/sport/percentage-reduce/assets/javascripts/reducer.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  ReduceData,
  Node
} = svs.components.sport.percentageReduce;
class Reducer {
  constructor(weights, reduceAmount) {
    let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    if (typeof reduceAmount !== 'number') {
      throw new Error('Amount must be a number.');
    }
    this.reduceData = [];
    this._setupDataStructure(weights);

    this.reduceAmount = reduceAmount;
    const defaultOptions = {
      randomizeEqualWeightThreshold: 0.2,
      randomizeBeginning: 65,
      weightThreshold: 0.5
    };
    this.options = _objectSpread(_objectSpread({}, defaultOptions), options);
  }
  _setupDataStructure(weights) {
    this.weights = weights;
    const outcomes = ['1', 'X', '2'];
    for (let i = 0; i < this.weights.length; ++i) {
      this.reduceData.push([]);
      for (let k = 0; k < this.weights[i].length; ++k) {
        this.reduceData[i].push(new ReduceData(i + 1, outcomes[k], this.weights[i][k]));
      }
    }

    this.sortMapping = [];

    const sortWeights = [];
    this.weights.forEach((weights, index) => {
      sortWeights.push({
        originalIndex: index,
        weights
      });
    });

    sortWeights.sort((aW, bW) => {
      let a = 0;
      let b = 0;
      aW.weights.forEach(weight => {
        a += weight !== 0 ? 1 : 0;
      });
      bW.weights.forEach(weight => {
        b += weight !== 0 ? 1 : 0;
      });
      if (a === b) {
        a = 0;
        b = 0;
        aW.weights.forEach(weight => {
          a += weight !== 0 ? 1 : 0;
        });
        bW.weights.forEach(weight => {
          b += weight !== 0 ? 1 : 0;
        });
        a = 100 / a;
        b = 100 / b;
        const aE = aW.weights.reduce((acc, weight) => Math.abs(a - weight) > acc && weight !== 0 ? Math.abs(a - weight) : acc, 0);
        const bE = bW.weights.reduce((acc, weight) => Math.abs(b - weight) > acc && weight !== 0 ? Math.abs(b - weight) : acc, 0);

        return aE === bE ? 0 : aE > bE ? -1 : 1;
      }
      return a > b ? 1 : -1;
    });

    sortWeights.forEach(_ref => {
      let {
        originalIndex
      } = _ref;
      this.sortMapping.push(originalIndex);
    });
  }
  _buildTree() {
    this.tree = new Node();
    let children = [this.tree];
    let newChildren = [];
    for (let i = 0; i < this.weights.length; ++i) {
      for (let l = 0; l < children.length; ++l) {
        for (let k = 0; k < this.weights[i].length; ++k) {
          if (this.weights[this.sortMapping[i]][k] === 0) {
            continue;
          }
          const node = new Node(children[l], this.reduceData[this.sortMapping[i]][k]);
          newChildren.push(node);
          children[l].children.push(node);
        }
      }
      children = newChildren;
      newChildren = [];
    }
  }
  reduce() {
    const reducedRows = [];
    const onlyOutcomeRows = [];
    this._buildTree();
    this.currentAmountOfReducedRows = 0;
    for (let i = 0; i < this.reduceAmount; ++i) {
      const uniqueRow = this._getUniqueRow(this.tree);
      if (uniqueRow.length === 0) {
        break;
      }
      this._maybeRemoveNode(uniqueRow[uniqueRow.length - 1]);
      this.currentAmountOfReducedRows += 1;
      reducedRows.push(uniqueRow);
    }
    for (let i = 0; i < reducedRows.length; ++i) {
      onlyOutcomeRows[i] = [];
      for (let k = 0; k < reducedRows[i].length; ++k) {
        onlyOutcomeRows[i][this.sortMapping[k]] = reducedRows[i][k].data.outcome;
      }
    }
    return onlyOutcomeRows;
  }

  _getUniqueRow(node) {
    let row = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    if (node.children.length === 0) {
      return row;
    }
    let selectedChild;
    if (this._getAmountOfRows() >= this.options.randomizeBeginning) {
      let useNeedy = false;
      node.children.forEach(child => {
        if (child.data.getDistanceFromTargetWeight() >= this.options.weightThreshold) {
          useNeedy = true;
        }
      });
      if (useNeedy) {
        selectedChild = node.getMostNeedyChild(this.options);
      } else {
        selectedChild = node.getRandomWeightedChild();
      }
    } else {
      selectedChild = node.getRandomWeightedChild();
    }
    selectedChild.data.picks += 1;
    selectedChild.calculateNewActual();
    row.push(selectedChild);
    return this._getUniqueRow(selectedChild, row);
  }
  _maybeRemoveNode(node) {
    if (!node.parent) {
      return; 
    }
    for (let i = 0; i < node.parent.children.length; ++i) {
      if (node.parent.children[i].data.outcome === node.data.outcome) {
        node.parent.children.splice(i, 1);
      }
    }
    let hasWorthyChildren = false;
    for (let i = 0; i < node.parent.children.length; ++i) {
      if (node.parent.children[i].data.weight > 0) {
        hasWorthyChildren = true;
      }
    }
    if (!hasWorthyChildren && node.parent) {
      this._maybeRemoveNode(node.parent);
    }
  }

  _getAmountOfRows() {
    return this.currentAmountOfReducedRows / this.reduceAmount * 100;
  }
}
setGlobal('svs.components.sport.percentageReduce.Reducer', Reducer);

 })(window);