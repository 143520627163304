(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/percentage-reduce/assets/javascripts/node.js') >= 0) return;  svs.modules.push('/components/sport/percentage-reduce/assets/javascripts/node.js');
"use strict";


const {
  randomFloatBetween,
  randIntBetween
} = svs.components.sport.percentageReduce.random;
class Node {
  constructor(parent, data) {
    this.children = [];
    this.parent = parent;
    this.data = data;
  }
  getRandomWeightedChild() {
    const sumChildrenWeights = this.children.reduce((sumWeight, node) => sumWeight + node.data.weight, 0);
    let rand = randomFloatBetween(1, sumChildrenWeights, 10);
    for (let i = 0; i < this.children.length; ++i) {
      if (rand <= this.children[i].data.weight) {
        return this.children[i];
      }
      rand -= this.children[i].data.weight;
    }
  }
  getMostNeedyChild(options) {
    let neediestChildren = [];
    let currentNeediness;
    for (let i = 0; i < this.children.length; ++i) {
      if (this.children[i].data.weight === 0) {
        continue;
      }
      const neediness = this.children[i].data.weight - this.children[i].data.actual;
      if (currentNeediness === undefined) {
        neediestChildren.push(this.children[i]);
        currentNeediness = neediness;
      } else if (Math.abs(neediness - currentNeediness) <= options.randomizeEqualWeightThreshold) {

        if (neediness > currentNeediness) {
          currentNeediness = neediness;
        }
        neediestChildren.push(this.children[i]);
      } else if (neediness > currentNeediness) {
        neediestChildren = [];
        neediestChildren.push(this.children[i]);
        currentNeediness = neediness;
      }
    }
    if (neediestChildren.length === 1) {
      return neediestChildren[0];
    }
    return neediestChildren[randIntBetween(0, neediestChildren.length - 1)];
  }
  calculateNewActual() {
    const parentChildren = this.parent.children;
    let totalPicks = 0;
    for (let i = 0; i < parentChildren.length; ++i) {
      totalPicks += parentChildren[i].data.picks;
    }
    for (let i = 0; i < parentChildren.length; ++i) {
      parentChildren[i].data.updateActual(totalPicks);
    }
  }
}
setGlobal('svs.components.sport.percentageReduce.Node', Node);

 })(window);